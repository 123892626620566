

















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class Dialog extends Vue {
    @Prop({
        type: [Object, Boolean, String, Number],
        default: false,
    })
    public value!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    public byVShow!: boolean;

    @Prop({
        type: [Number, String],
        default: '',
    })
    public width!: number | string;

    public close() {
        this.$emit('input', false);
        this.$emit('close');
    }

    public closeByOverlay(e: MouseEvent) {
        const { container } = this.$refs as { [k: string]: HTMLElement };

        if (e.target !== container) return;

        this.close();
    }
}
