



































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { RouterLink } from 'vue-router';
import moment from 'moment';
import Dialog from '@/modules/common/components/ui-kit/dialog.vue';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import NotificationsService, { NotificationsServiceS } from '../notifications.service';
import NotificationsStore from '../store/notifications-store';

@Component({
    components: {
        // RouterLink,
        ProviderCard,
        Dialog,
    },
})
export default class NotificationCard extends Vue {
    @Inject(NotificationsServiceS) notificationsService!: NotificationsService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;

    readonly storeState: NotificationsStore = this.storeFacade.getState('NotificationsStore');

    @Prop()
    id!: string;

    @Prop()
    insightType!: {
        name: string;
        color: string;
        bgcolor: string;
    };

    @Prop()
    provider!: string;

    @Prop()
    date!: string;

    @Prop()
    contentLink!: string;

    @Prop({
        default: false,
    })
    isNew!: boolean;

    @Prop({
        default: false,
    })
    isDeleted!: boolean;

    @Prop({
        default: null,
    })
    seeMore!: () => void | null;

    isPending = false;
    isDeleteConfirmation = false;

    async handleToggleViewed() {
        this.isPending = true;
        if (this.isNew) {
            await this.notificationsService.markAsRead(this.id);
        } else {
            await this.notificationsService.markAsUnRead(this.id);
        }
        this.isPending = false;
    }

    async handleDelete() {
        this.isPending = true;
        await this.notificationsService.deleteAlertById(this.id);
        this.isPending = false;
        this.isDeleteConfirmation = false;
    }
}
