




















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DotsSpinner from '@/modules/common/components/dots-spinner.vue';
import CarAlertModel from '@/modules/cars/alerts/models/car.alert.model';
import moment from 'moment';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import DocumentFiltersService, {
    DocumentFiltersServiceS,
} from '@/modules/document-filters/document-filters.service';
import Month from '@/modules/common/types/month.type';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import VEHICLE_TYPE_FILTER from '@/modules/cars/constants/vehicle-type-filter.constant';
import { RouteUtils } from '../../../utils/route.util';
import NotificationsService, { NotificationsServiceS } from '../notifications.service';
import NotificationCard from './notification-card.vue';
import NotificationsStore from '../store/notifications-store';
import { CarAlertType } from '../../settings/enums/alerts.enum';

export enum AlertType {
    SIPP_CODE = 'sipp_code',
    CAR_CATEGORY = 'car_category',
    RATE_DIFF = 'rate_diff',
}
@Component({
    components: {
        NotificationCard,
        DotsSpinner,
    },
})
export default class NotificationGrid extends Vue {
    @Inject(NotificationsServiceS) notificationsService!: NotificationsService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(DocumentFiltersServiceS)
    protected documentFiltersService!: DocumentFiltersService;
    @Inject(CarsServiceS) private carsService!: CarsService;

    readonly storeState: NotificationsStore = this.storeFacade.getState('NotificationsStore');
    readonly PAGE_MAX_NUMBER = 4;
    readonly NOTIFICATION_PARE_PAGE = 50;
    currentNotifications: CarAlertModel[] = [];

    typeColor(type: CarAlertType) {
        // Add more colors from design if needed
        const colorPairs = [
            ['#52A9FF', 'rgba(82, 169, 255, 0.06)'],
            ['#A7881C', 'rgba(255, 238, 82, 0.08)'],
            ['#EE2841', 'rgba(241, 73, 80, 0.06)'],
            ['#555', 'rgba(170, 170, 170, 0.06)'],
            ['#21A433', 'rgba(57, 181, 74, 0.06)'],
        ];

        const typeIndex = Object.values(CarAlertType).findIndex(item => item === type);
        const colorIndex = typeIndex % colorPairs.length;

        return colorPairs[colorIndex];
    }
    get notifications() {
        this.currentNotifications = this.notificationsService.notifications.slice(
            0,
            this.storeState.page * this.NOTIFICATION_PARE_PAGE,
        );
        return this.currentNotifications.map(ins => {
            const [color, bgcolor] = this.typeColor(ins.alertType);
            return {
                // eslint-disable-next-line no-underscore-dangle
                id: ins.id,
                insightType: {
                    name: ins.title,
                    color,
                    bgcolor,
                },
                provider: ins.extData?.provider?.[0]?.provider || '',
                read: ins.read,
                deleted: false,
                content: {
                    text: ins.message,
                    // eslint-disable-next-line no-underscore-dangle
                    link: null,
                },
                date: this.getFormattedDate(ins.pickUpDate, ins.alertType),
                seeMore: ins.hasLink ? () => this.handleViewButton(ins) : null,
            };
        });
    }

    async handleViewButton(alert: CarAlertModel) {
        const { params, sessionData } = alert.extData.link;
        sessionData.includeVan = alert.extData.provider.map((item: { includeVan: boolean }) => item.includeVan);
        sessionData.providers = alert.alertType === CarAlertType.COMPSET_DIFF ? alert.extData.provider.map((item: { provider: string }) => item.provider) : null;
        this.$router.push({
            name: RouteUtils.getNotificationRoute(this.$router.currentRoute?.name || ''),
            params: {
                ...params,
                sessionData,
                alertMessage: alert.message,
                alertDate: alert.updatedAt,
            },
        });
        if (this.$parent && this.$parent.$parent && this.$parent.$parent.$refs.popover) {
            const popOverElement = this.$parent.$parent.$refs.popover as HTMLElement;
            popOverElement.style.visibility = 'hidden';
        }
    }

    getFormattedDate(date: Date, alertType: CarAlertType) {
        const dateString = moment(date).format('MMMM DD, YYYY');
        const prefixDate = [CarAlertType.CAR_CATEGORY, CarAlertType.SIPP_CODE].includes(alertType) ? 'Generation Date' : 'Pick Up Date';
        return `${prefixDate} ${dateString}`;
    }

    handleGridScroll(e: UIEvent) {
        if (this.storeState.page > this.PAGE_MAX_NUMBER) {
            return;
        }

        const el = e.target as HTMLElement;
        const loadingOffset = el.scrollHeight * 0.2;
        if (
            el.scrollTop + el.offsetHeight > el.scrollHeight - loadingOffset
            && !this.notificationsService.isLoading
        ) {
            this.storeState.page++;
        }
    }
}
