export const defaultPercentageVal = [
    { name: '5', value: 5 },
    { name: '10', value: 10 },
    { name: '15', value: 15 },
    { name: '20', value: 20 },
    { name: '30', value: 30 },
    { name: '40', value: 40 },
];

export const defaultTrendVal = [
    { name: 'Any', value: 1 },
    { name: 'Higher', value: 2 },
    { name: 'Lower', value: 3 },
];

export enum CarAlertStatusType { ACTIVE = 'active', INACTIVATE = 'inactive' }
