













import { Component, Vue } from 'vue-property-decorator';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import NotificationHeader from '../components/notification-header.vue';
import NotificationFilters from '../components/notification-filters.vue';
import NotificationGrid from '../components/notification-grid.vue';

@Component({
    components: {
        PageWrapper,
        NotificationHeader,
        NotificationFilters,
        NotificationGrid,
    },
})
export default class NotificationPage extends Vue {}
