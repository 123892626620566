





































































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import Item from '@/modules/common/interfaces/item.interface';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import { DatePicker, Dialog } from 'element-ui';
import * as moment from 'moment';
import NotificationsStore from '../store/notifications-store';
import NotificationsService, { NotificationsServiceS } from '../notifications.service';

@Component({
    components: {
        CustomSelect,
        DatePicker,
        Dialog,
    },
})
export default class NotificationHeader extends Vue {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(NotificationsServiceS) notificationsService!: NotificationsService;

    readonly storeState: NotificationsStore = this.storeFacade.getState('NotificationsStore');
    showDeleteAllNotifications = false;
    mounted() {
        this.storeState.selectedDate = 1;
        const end = new Date();
        const start = new Date();
        end.setTime(start.getTime() + 3600 * 1000 * 24);
        this.storeState.selectedDatesRange = [start, end];
        this.storeState.dateFilterReady = true;
    }

    closeDialog() {
        this.showDeleteAllNotifications = false;
    }

    async deleteAllNotification() {
        await this.notificationsService.deleteAll();
        this.storeState.notifications = [];
        this.showDeleteAllNotifications = false;
    }

    isDisabledDate(date: Date) {
        return moment.utc(date).isBefore(moment.utc().subtract(1, 'days'));
    }

    get dateRangeItems(): Item[] {
        return [{
            name: 'Today',
            value: 1,
        }, {
            name: 'Next 7 days',
            value: 7,
        }, {
            name: 'Next 14 days',
            value: 14,
        }, {
            name: 'Next 30 days',
            value: 30,
        }, {
            name: 'Next 60 days',
            value: 60,
        }, {
            name: 'Next 90 days',
            value: 90,
        }];
    }

    get dateRange() {
        return this.storeState.selectedDate;
    }
    get selectedDates() {
        return this.storeState.selectedDatesRange;
    }
    set selectedDates(val: any) {
        this.dateRangeItems.push({
            name: `${val[0]} : ${val[1]}`,
            value: 0,
            disabled: true,
            hide: true,
        });
        this.storeState.selectedDate = 0;
        this.storeState.selectedDatesRange = val;
    }

    set dateRange(val) {
        const end = new Date();
        const start = new Date();
        end.setTime(start.getTime() + 3600 * 1000 * 24 * Number(val));
        this.storeState.selectedDatesRange = [start, end];
        this.storeState.selectedDate = val;
    }
}
